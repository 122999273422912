var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page mango-page"},[(_vm.status)?_c('a-player',{attrs:{"autoplay":true,"volume":0.3,"music":{
      title: this.play.client ? _vm.transformName(this.play.client) : '',
      artist: this.play.client
        ? this.play.client.phone
        : _vm.transformPhone(this.play.to.number),
      src: this.play.recording_url,
      pic: '',
    }},on:{"focus":_vm.togglePlay}}):_vm._e(),_c('v-page-header',{attrs:{"title":_vm.$t('pages.mango.pageTitle'),"icon":"mango_title"}}),_c('div',{staticClass:"page__body d-flex"},[_c('div',{staticClass:"page__left"},[_c('v-filter',{attrs:{"type":"mango"},on:{"refreshDates":_vm.refreshDates}})],1),_c('div',{staticClass:"page__right"},[(!_vm.isLoading)?_c('v-spinner'):(_vm.dataset.length)?[_c('div',{staticClass:"scroll-horizontal"},[_c('div',{staticClass:"list list-shadow"},[_c('div',{staticClass:"list__header"},[_c('div',{staticClass:"list__title"},[_vm._v(" "+_vm._s(_vm.$t("pages.mango.pageTitle"))+" ")]),_c('div',{staticClass:"list__columns"},_vm._l((_vm.$t('pages.mango.fields')),function(field){return _c('div',{staticClass:"list__column"},[_vm._v(" "+_vm._s(field)+" ")])}),0)]),_vm._l((_vm.dataset),function(item,index){return _c('div',{key:item._id,staticClass:"list__row list__row--shadow list__row--white"},[_c('v-mango',{attrs:{"index":index,"item":item,"play":_vm.play,"status":_vm.status},on:{"togglePlay":_vm.togglePlay}})],1)})],2)]),_c('v-pagination',{attrs:{"count":_vm.count}})]:_c('v-not-found-query')],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }